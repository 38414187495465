.modal {
  top: 50%;
  left: 50%;
  width: 400;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 15px;
  background-color: #bed4e9;
  box-shadow: 0px 3px 2px #ccc;
}
