.checkout-section {
  padding: 25px 50px;
  text-align: left;
}

.order-details span {
  margin: 15px;
  display: inline-block;
}

.order-details .title {
  font-size: large;
  font-weight: bold;
  text-transform: uppercase;
}

.order-details .order-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 14px;
}

.order-details .order-data.order-total {
  font-size: 20px;
}
