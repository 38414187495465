.header {
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  padding: 0 30px;
  z-index: 99;
  box-sizing: border-box;
  background-color: #d3dfeb;
  box-shadow: 0px 3px 2px #ccc;
  display: flex;
  justify-content: center;
}

.header .logo {
  object-fit: cover;
  cursor: pointer;
}
