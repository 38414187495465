.App {
  text-align: center;
}

.App section.App-Content {
  width: 100%;
  height: calc(100vh - 75px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 25px 100px 0;
  box-sizing: border-box;
}
