.form .form-row {
  display: flex;
  flex-direction: row;
}

.form .form-row > div {
  flex: 1;
  margin: 15px 0;
}

.form .form-row > div:first-child {
  margin-right: 15px;
}

p {
  margin: 5px 0;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
